import React from 'react';
import { FaFacebookF, FaInstagram, FaLinkedinIn } from 'react-icons/fa'; // Importamos los íconos necesarios

const Footer = () => {
  return (
    <footer className="bg-gray-800 p-6 text-white">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-4">
        <div>
          <h3 className="text-lg font-bold">e-trends</h3>
          <p>Soluciones de audio profesional para empresas y comercios.</p>
        </div>
        <div>
          <h3 className="text-lg font-bold">Enlaces útiles</h3>
          <ul>
            <li><a href="#home" className="hover:underline">Inicio</a></li>
            <li><a href="#about" className="hover:underline">Quiénes Somos</a></li>
            <li><a href="#projects" className="hover:underline">Proyectos</a></li>
            <li><a href="#contact" className="hover:underline">Contacto</a></li>
          </ul>
        </div>
        <div>
          <h3 className="text-lg font-bold">Síguenos</h3>
          <div className="flex space-x-4 mt-2">
            {/* Ícono de Facebook */}
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="hover:text-blue-500">
              <FaFacebookF className="w-6 h-6" />
            </a>
            {/* Ícono de Instagram */}
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="hover:text-pink-500">
              <FaInstagram className="w-6 h-6" />
            </a>
            {/* Ícono de LinkedIn */}
            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="hover:text-blue-400">
              <FaLinkedinIn className="w-6 h-6" />
            </a>
          </div>
        </div>
      </div>
      <div className="text-center mt-4">
        <p>&copy; 2024 e-trends. Todos los derechos reservados.</p>
      </div>
    </footer>
  );
};

export default Footer;