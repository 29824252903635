import React from 'react';
import { FaWhatsapp } from 'react-icons/fa'; // Instalaremos una librería para los iconos

const WhatsAppButton = () => {
  return (
    <a
      href="https://wa.me/5491125313874" // Reemplaza por tu número de WhatsApp
      className="fixed bottom-4 right-4 bg-green-500 text-white p-4 rounded-full shadow-lg hover:bg-green-600 transition-transform transform hover:scale-110"
      target="_blank"
      rel="noopener noreferrer"
    >
      <FaWhatsapp className="w-8 h-8" />
    </a>
  );
};

export default WhatsAppButton;