import React from 'react';
import Home from './pages/Home';
import WhatsAppButton from './components/WhatsAppButton';



const App = () => {
  return (
    <div>
      <Home />
      <WhatsAppButton />
    </div>
  );
};

export default App;