import React, { useState } from 'react';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false); // Cerrar el menú al hacer clic en un enlace
  };

  return (
    <header className="bg-blue-600 p-4 text-white fixed top-0 w-full z-50 shadow-lg">
      <div className="container mx-auto flex justify-between items-center">
        <h1 className="text-2xl font-bold">e-trends</h1>

        {/* Botón del menú hamburguesa para móviles */}
        <button onClick={toggleMenu} className="md:hidden block focus:outline-none">
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d={isOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16M4 18h16'}
            />
          </svg>
        </button>

        {/* Menú de navegación visible en pantallas medianas y grandes */}
        <nav className="hidden md:block">
          <ul className="flex space-x-4">
            <li><a href="#home" className="hover:underline">Inicio</a></li>
            <li><a href="#about" className="hover:underline">Quiénes Somos</a></li>
            <li><a href="#projects" className="hover:underline">Proyectos</a></li>
            <li><a href="#contact" className="hover:underline">Contacto</a></li>
          </ul>
        </nav>

        {/* Menú desplegable para móviles */}
        {isOpen && (
          <nav className="absolute top-16 left-0 w-full bg-blue-600 md:hidden">
            <ul className="flex flex-col items-center space-y-2 py-4">
              <li><a href="#home" onClick={closeMenu} className="hover:underline">Inicio</a></li>
              <li><a href="#about" onClick={closeMenu} className="hover:underline">Quiénes Somos</a></li>
              <li><a href="#projects" onClick={closeMenu} className="hover:underline">Proyectos</a></li>
              <li><a href="#contact" onClick={closeMenu} className="hover:underline">Contacto</a></li>
            </ul>
          </nav>
        )}
      </div>
    </header>
  );
};

export default Header;