import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import WhatsAppButton from '../components/WhatsAppButton';
import backgroundImage from '../assets/background.jpg'; // Importar imágenes desde src/assets
import reunionesImage from '../assets/reuniones.jpg';
import baresImage from '../assets/bares.jpg';
import tiendasImage from '../assets/tiendas.jpg';
import proyecto1Image from '../assets/proyecto1.jpg';
import proyecto2Image from '../assets/proyecto2.jpg';
import proyecto3Image from '../assets/proyecto3.jpg';
import workImage from '../assets/work.jpg';

const Home = () => {
  return (
    <div>
      <Header />

      {/* Metadatos */}
      <head>
        <title>Soluciones de Audio Profesional para Empresas y Comercios | e-trends</title>
        <meta name="description" content="En e-trends ofrecemos soluciones de audio personalizadas para empresas, bares y comercios. Más de 25 años optimizando acústica." />
        <meta name="keywords" content="soluciones de audio, instalación de audio, acústica empresarial, sistemas de sonido para bares, optimización acústica" />
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </head>

      {/* Sección de Bienvenida con H1 optimizado */}
      <section
        id="home"
        className="h-screen bg-cover bg-center flex items-center justify-center pt-20"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="bg-black bg-opacity-50 p-10 rounded-lg text-center text-white">
          <h1 className="text-5xl font-bold mb-4">Soluciones de Audio Profesional para Empresas y Comercios</h1>
          <p className="text-xl mb-4">Ofrecemos instalación y optimización de sistemas de sonido para oficinas, bares y tiendas comerciales.</p>
          <button className="mt-4 bg-blue-600 text-white px-6 py-3 rounded-lg shadow-lg hover:bg-blue-500 transition">
            Contáctanos
          </button>
        </div>
      </section>

      <section id="about" className="container mx-auto my-20 p-10 pt-20">
        <h2 className="text-3xl font-bold text-center mb-8">Quiénes Somos</h2>
        <div className="flex flex-col md:flex-row items-center">
          <div className="md:w-1/2 mb-6 md:mb-0">
            <img
              src={workImage} // Usar la variable de la imagen importada
              alt="Equipo de e-trends trabajando en soluciones de audio"
              className="rounded-lg shadow-lg w-full"
            />
          </div>
          <div className="md:w-1/2 md:pl-10">
            <p className="text-lg text-center md:text-left mb-4">
              En <strong>e-trends</strong>, contamos con más de 25 años de experiencia ofreciendo soluciones de audio profesional. Nuestro equipo está compuesto por expertos en acústica, que se dedican a mejorar la calidad del sonido en empresas, bares y comercios.
            </p>
            <p className="text-lg text-center md:text-left">
              Nos especializamos en crear experiencias acústicas que no solo mejoran la comunicación, sino que transforman los ambientes, proporcionando una atmósfera envolvente y agradable para empleados, clientes y visitantes. 
            </p>
          </div>
        </div>
      </section>

      {/* Sección de Servicios */}
      <section id="services" className="container mx-auto my-20 p-10 pt-20">
        <h2 className="text-3xl font-bold text-center mb-8">Nuestros Servicios</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="p-6 bg-white rounded-lg shadow-lg">
            <img src={reunionesImage} alt="Instalación de audio en salas de reuniones" className="w-full h-48 object-cover rounded-t-lg mb-2" />
            <h3 className="text-xl font-bold mb-2">Sistemas de Audio para Salas de Reuniones</h3>
            <p>Mejora la comunicación en tu empresa con sistemas de audio optimizados.</p>
          </div>
          <div className="p-6 bg-white rounded-lg shadow-lg">
            <img src={baresImage} alt="Sistemas de sonido para bares" className="w-full h-48 object-cover rounded-t-lg mb-2" />
            <h3 className="text-xl font-bold mb-2">Audio para Bares y Restaurantes</h3>
            <p>Transforma la experiencia de tus clientes con un sonido envolvente en tus espacios.</p>
          </div>
          <div className="p-6 bg-white rounded-lg shadow-lg">
            <img src={tiendasImage} alt="Sistemas de audio para tiendas comerciales" className="w-full h-48 object-cover rounded-t-lg mb-2" />
            <h3 className="text-xl font-bold mb-2">Soluciones de Sonido para Tiendas Comerciales</h3>
            <p>Mejora la experiencia de compra con una acústica personalizada para tu negocio.</p>
          </div>
        </div>
      </section>

      {/* Sección de Proyectos */}
      <section id="projects" className="container mx-auto my-20 p-10 bg-gray-100 rounded-lg pt-20">
        <h2 className="text-3xl font-bold text-center mb-8">Casos de Éxito</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="bg-white rounded-lg shadow-lg p-6">
            <img src={proyecto1Image} alt="Instalación de audio en restaurante gourmet" className="w-full h-48 object-cover rounded-lg mb-4" />
            <h4 className="text-xl font-bold">Restaurante Gourmet XYZ</h4>
            <p>Implementación de un sistema de sonido que mejora la experiencia del cliente.</p>
          </div>
          <div className="bg-white rounded-lg shadow-lg p-6">
            <img src={proyecto2Image} alt="Sistemas de sonido en sala de reuniones corporativa" className="w-full h-48 object-cover rounded-lg mb-4" />
            <h4 className="text-xl font-bold">Sala de Reuniones Corporativa ABC</h4>
            <p>Optimización acústica para mejorar la calidad de las conferencias.</p>
          </div>
          <div className="bg-white rounded-lg shadow-lg p-6">
            <img src={proyecto3Image} alt="Sistema de audio en tienda de lujo" className="w-full h-48 object-cover rounded-lg mb-4" />
            <h4 className="text-xl font-bold">Tienda de Lujo DEF</h4>
            <p>Creación de una experiencia de compra envolvente con soluciones acústicas avanzadas.</p>
          </div>
        </div>
      </section>

      {/* Sección de Contacto */}
      <section id="contact" className="container mx-auto my-20 p-10 pt-20">
        <h2 className="text-3xl font-bold text-center mb-8">Contáctanos</h2>
        <form className="w-full max-w-lg mx-auto bg-white p-6 rounded-lg shadow-lg">
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">Nombre</label>
            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700" id="name" type="text" placeholder="Tu nombre" />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">Email</label>
            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700" id="email" type="email" placeholder="Tu email" />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="message">Mensaje</label>
            <textarea className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700" id="message" placeholder="Escribe tu mensaje" rows="4"></textarea>
          </div>
          <button className="bg-blue-600 text-white px-4 py-2 rounded-lg shadow-lg hover:bg-blue-500 transition" type="submit">Enviar</button>
        </form>
      </section>

      <Footer />
      <WhatsAppButton />
    </div>
  );
};

export default Home;